<template>
  <div class="s-layout" :style="colorObject">
    <Loading v-if="isLoading" loading-message="載入中..." :background-color="'#ffffff'"/>
    <div class="s-container">
      <div>
        <Header
          :page-title="getModuleConfig('page_title')"
          :page-image="getModuleConfig('page_image')"
          :page-kv-image="getModuleConfig('page_kv_image')"
          :header-section="getModuleConfig('header_section')"
          :meta="meta"
        />
        <!-- page: status -->
        <div class="page">
          <div class="card">
            <div class="card__info">
              <div class="card__title">
                {{ displayText('record_title', '活動頁') }}
              </div>
              <div class="card__text py-1">
                {{ displayText('record_customer', '會員') }} {{ user.name }}
              </div>
              <div class="card__text py-1">
                {{ displayText('record_status', '狀態') }} {{ customerStatus }}
              </div>
            </div>
          </div>

          <div>
            <h3 class="text-center s-title py-2">{{ displayText('record_point_title', '消費紀錄') }}</h3>
            <h5 class="text-center s-subtitle py-1">{{ displayText('record_point_text', '可獲得點數:') }} {{ points }} 點</h5>
          </div>

          <div class="card">
            <div class="card__info">
              <div class="card_text py-1">
                <b>{{ displayText('record_product_no', '商品編號:') }}</b> {{ redeem_code.redeem_code_bundle.config.product_code }}
              </div>
              <div class="card_text py-1">
                <b>{{ displayText('record_product_name', '商品名稱:') }}</b> {{ redeem_code.redeem_code_bundle.name }}
              </div>
              <div class="card_text py-1">
                <b>{{ displayText('record_redeem_date', '歸戶日期:') }}</b> {{ displayDate(redeem_code.redeem_at) }}
              </div>
              <div class="card_text py-1">
                <b>{{ displayText('record_product_points', '可獲得點數:') }}</b> {{ redeem_code.redeem_code_bundle.config.reward_points }} 點
              </div>
            </div>
          </div>

          <div class="btn">
            <MemberButton
              :button-text="displayText('cancel_button_text', '返回官方帳號')"
              :button-url="displayText('cancel_button_url', '')"
              :type="displayText('cancel_button_action', 'CLOSE')"
            ></MemberButton>
          </div>
        </div>
        <!-- page: status -->
      </div>
    </div>
  </div>
</template>

<script>
// Packages
import { mapGetters, mapState } from "vuex";
import { themeColorFn } from "@/mixins/liff/themeColor";
import dateOptionMixin from "@/mixins/liff/dateOption";
import { format } from "date-fns";
import https from "@/apis/liff/v2/https";
// Components
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import Loading from "@/components/Page/Liff/Shared/Loading";
import liff from "@line/liff";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";

export default {
  mixins: [
    themeColorFn({ themeConfigPage: 'redeem' }),
    dateOptionMixin,
  ],
  components: {
    Header,
    Loading,
    MemberButton,
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      redeem_code_hashid: null,
      isLoading: false,
      points: null,
      redeem_code: {},
      event: {
        config: {},
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.liffAuth.user,
    }),
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
    }),
    customerStatus() {
      if (this.user.phone_verified_at) {
        return "手機已驗證"
      }
      if (this.user.registered_at) {
        return "註冊完畢"
      }
      else {
        return "未註冊"
      }
    }
  },

  methods: {
    displayDate(date) {
      return format(new Date(date), "yyyy-MM-dd HH:mm");
    },
    async init() {
      try {
        this.isLoading = true;
        this.redeem_code_hashid = this.$route.params.redeem_code_hashid;

        let response = await https.get(`${this.$store.state.liffGeneral.orgCode}/liff/clinique/code`, {
          params: {
            redeem_code_hashid: this.redeem_code_hashid,
          }
        });

        this.redeem_code = response.data.data;
        this.points = response.data.points.point;

        console.log(response);
      } catch (error) {
        console.error(error);

        if (error.response.data.message === 'REDEEM_CODE_NOT_FOUND') {
          this.$swal("錯誤", "找不到此序號", "error");
        }

      } finally {
        this.isLoading = false;
      }
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("liff_redeem", key);
    },
    displayText(key, defaultText) {
      return this.redeem_code.event?.config?.[key] ?? defaultText;
    },
    cancel() {
      liff.closeWindow();
    },
  }
}
</script>

<style lang="scss">
@import "../../../../assets/css/liff/main.css";
@import "../../../../assets/css/liff/liff_init_theme_config.css";
@import '../../../../assets/scss/shared/components/_fields.scss';

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
}

.s-container {
  min-height: 100vh;
  position: relative;
  max-width: 768px;
  margin: auto;
}

.form {
  padding: 0 5px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}

.content {
  height: calc(100vh - 56px);
  overflow-y: auto;
  margin: 0 24px;
  padding-bottom: 24px;
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
}

.logo {
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: 300px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


.card {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px;
  margin-top: 12px;

  &__title {
    color: #2c2c2e;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &__divider {
    border-bottom: 2px dashed #e5e5ea;
  }
  &__info {
    color: #636366;
    line-height: 24px;
    font-size: 16px;
    padding-top: 12px;
    b {
      color: #2c2c2e;
      font-weight: 500;
    }
  }
}


.redeem {
  background: url("../images/bg_redeem.png");
  background-size: cover;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  &__code {
    color: #2c2c2e;
    font-size: 19px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }

  &__input {
    background: #ffffff;
    border: 1px solid #636366;
    border-radius: 10px !important;
    border: 1px solid #636366;
    padding: 15px 12px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 32px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }
  }
}

.btn {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;

  button {
    background: var(--liff-button-color);
    color: var(--liff-button-text-color);
  }
}

.gift__image {
  width: 100%;
  object-fit: contain;
}
.loading-spinner {
  order: 4;
}


.custom-checkbox {
  padding-left: 1.5rem;
  margin-right: 1rem;

  .custom-control-input {
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      color: var(--liff-primary_text_color);
      font-size: 16px;
    }

    & + label:before {
      content: "";
      margin-right: 8px;
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      border: 1px solid var(--liff-button-color) !important;
      border-radius: 4px;
      background: white;
      left: -1.5rem;
    }

    & + label::after {
      left: -1.5rem;
    }

    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before,
    &:active + label:before {
      background: var(--liff-button-color) !important;
    }

    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: var(--s-gray-light);
    }
  }
}

.fixed-footer{
  position:sticky;
  bottom: 0;
  z-index: 1;
}

.field__label {
  font-size: 16px !important;
}

.field__desc {
  font-size: 14px !important;
}

.search {
  &__input {
    background: #ffffff;
    border-radius: 10px !important;
    border: 1px solid var(--liff-button-color);
    padding: 15px 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }

    &:focus {
      border: 1px solid var(--liff-button-color);
      outline: none;
    }
  }
}

.scan-content {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}

.scan-filter {
  display: flex;
  justify-content: center;
}

.s-title {
  font-size: 26px;
}

.s-subtitle {
  font-size: 20px;
}

</style>
